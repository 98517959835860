$darkGrayColor: #424651;
$primaryColor: #1D6AAE;
$secondaryColor: #222230;
$errorColor: #ED474A;

html *{
  -webkit-font-smoothing: antialiased;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

body,html {
  background-color: $darkGrayColor !important;
}

.center {
  text-align: center;
}

button.primary {
  color: white;
  background-color: $primaryColor;
  border-radius: 30px;
  border: none;
  min-width: 160px;
  min-height: 50px;

  &:hover {
    background-color: $primaryColor;
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px black;
  }
}

button.secondary {
  color: white;
  background-color: $secondaryColor;
  border-radius: 30px;
  border: none;
  min-width: 160px;
  min-height: 50px;

  &:hover {
    background-color: $secondaryColor;
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px black;
  }
}

button.action{
  background: none;
  color: black;
  border: none;

  &:hover {
    transform: scale(1.5);
    background: none;
    color: black;
    transition: 0.25s;
  }
}

.btnAction {
  background: none !important;
  color: #011627 !important;
  border: none !important;
  transition: 0.4s;

  &:hover {
    color: #011627;
    transition: 0.4s;
    transform: scale(1.8);
  }
}

.form-control {
  border-radius: 10px !important;
  border: 1px solid #979797 !important;
}

.divider {
  font-size: 1rem;
  font-weight: 400;
}

hr {
  border-top: 1px solid black !important;
  margin: .5rem 0 .5rem 0 !important;
}

div.form-group > small {
  color: $errorColor !important;
}

//modal
.modal-header {
  background: #011627;
  border-radius: 24px 24px 0 0 !important;
  color: white;
  text-align: center !important;
}

.modal-content {
  border-radius: 25px !important;
}

.modal-header .close {
  color: white;
}

.modal-title {
  margin-left: auto;
  font-weight: 400;
}

.modal-header .close:hover {
  color: white;
}

/*=== Trigger  ===*/
.animate {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/*=== Optional Delays, change values here  ===*/
.one {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.two {
  -webkit-animation-delay: 1.7s;
  -moz-animation-delay: 1.7s;
  animation-delay: 1.7s;
}

.three {
  -webkit-animation-delay: 2.3s;
  -moz-animation-delay: 2.3s;
  animation-delay: 2.3s;
}

.four {
  -webkit-animation-delay: 3.3s;
  -moz-animation-delay: 3.3s;
  animation-delay: 3.3s;
}

/*===RESPONSIVE===*/


@media screen and (max-width: 1200px){

  .dashboard-content{
    margin: -70px 0 0 0;
    transition: .25s;
  }
}


/*=== Animations start here  ===*/
/*=== FADE IN  ===*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.fade-out.one {-webkit-animation-delay: .5s;}

@-webkit-keyframes fade-out {
  0% { opacity: 1; -webkit-transform: scale(1);}
  85% {opacity: 1; -webkit-transform: scale(1.05);}
  100% {-webkit-transform: scale(.1); opacity: 0;}
}

.fade-out {
  -webkit-animation: fade-out .5s ease-in;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 1;
  background-color: #000;
  width: 100px;
  height: 100px;
  opacity: 1;
}

/*=== FADE IN DOWN ===*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

/*==== FADE IN UP ===*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/*=== FADE IN LEFT ===*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}


/*==== FADE IN RIGHT ===*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;


}
